<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container-fluid mt-5">
    <div id="incluir-div" class="container col-xl-11 col-12">
        <app-buscar-cabecalho-lista></app-buscar-cabecalho-lista>

        <div class="col pl-4 pr-4">
            <div class="mt-4 row align-items-center justify-content-between">
                <div class="mb-3">
                    <div class="row">
                        <img src="assets/imagens/seta-navegacao.svg" class="mr-2">
                        <h6 class="mr-2">{{linhas.linhasAPIClicada.linha}}</h6>

                        <img src="assets/imagens/seta-navegacao.svg" class="mr-2">
                        <h6 class="mr-2"> {{linhas.gruposAPIClicada.grupo}}</h6>
                    </div>
                </div>

                <div class="row ml-2 mr-2  mb-3 text-right">
                    <ng-container *ngIf="linhas.subGruposAPI.length > 1">
                        <ng-container *ngFor="let subgrupo of linhas.subGruposAPI; index as i">
                            <h6 *ngIf="i != 0" class="mr-2 ml-2"> | </h6>
                            <a (click)="linhas.ativarSubgrupo(subgrupo.id)">
                                <h6 [ngClass]="linhas.subGrupoAtivo == subgrupo.id ?'grupo-subgrupo-selecionado': false">
                                    {{subgrupo.subgrupo}}</h6>
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="row lista-produtos">
                <ng-container *ngFor="let produto of produtos.produtosNomeApi; index as i">
                    <a *ngIf="linhas.subGrupoAtivo == produto.cod_subgrupo|| linhas.subGrupoAtivo  == 0"
                        [id]='produto.id' (click)='
                            produtos.indexClicado(produto.cod_index);
                            produtos.ativarNomeProdutoAtivo(produto.id);
                            produtos.exibirModal();
                            produtos.carregarProdutoForm();
                            ' class="link-produto-unico" [ngClass]="produtos.classeProdutoUnico"
                        [routerLink]="produto.id">

                        <app-produto-unico [nome]="produto.nome" [fornecedor]="produto.fornecedor"
                            [codFornecedor]="produto.cod_fornecedor" [id]="produto.id">
                        </app-produto-unico>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</div>
