import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/servicos/global.service';
import { ProdutosService } from '../produtos.service';

@Component({
  selector: 'app-produto-lista-fornecedor',
  templateUrl: './produto-lista-fornecedor.component.html',
  styleUrls: ['./produto-lista-fornecedor.component.css']
})
export class ProdutoListaFornecedorComponent implements OnInit {

  tituloPaginas = 'PRODUTOS POR FORNECEDOR';
  corTitulo = "produto-fornecedor";
  corTituloSvg = "produto-fornecedor-svg";


  constructor(
    private rotaAtiva: ActivatedRoute,
    public produtos: ProdutosService,
    public global: GlobalService
  ) {

    this.global.voltarTopoPagina();

  }


  pegarIdFornecedorURL() {
    this.rotaAtiva.paramMap.subscribe(params => {
      let fornecedorAtivo = parseInt(params.get("idFornecedor"));
      this.produtos.fornecedorAtivo = fornecedorAtivo
      this.produtos.buscarProdutosFornecedor();
    });
  }

  pegarIdProdutoURL() {
    this.rotaAtiva.paramMap.subscribe(params => {
      let produtoAtivo = parseInt(params.get("idProduto"));
      // this.produtos.ativarNomeProdutoAtivo(produtoAtivo);
    });
  }

  ngOnInit(): void {
    this.pegarIdProdutoURL();
    this.pegarIdFornecedorURL();
    this.produtos.exibirProdutoNomeIndex();
  }

}
