import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buscar-cabecalho-lista',
  templateUrl: './buscar-cabecalho-lista.component.html',
  styleUrls: ['./buscar-cabecalho-lista.component.css']
})
export class BuscarCabecalhoListaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
