import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../servicos/global.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  filiais:any = [
    {
      'id': 2,
      'filial': 'FILIAL DISTRITO FEDERAL',
      'endereco': 'Polo de Desenvolvimento JK Trecho 05 Conjunto 03 Lote 1 - Santa Maria-DF, 72549-720',
      'telefone': '(61) 3355-5100 | (61) 3025-9500',
      'imagemFilial': 'assets/imagens/filial-df.jpg'
    },

    {
      'id': 4,
      'filial': 'FILIAL GOIÁS',
      'endereco': 'Rodovia BR 153 - KM 05, Galpão 04 Módulo 01/02 - Jardim Guanabara, Goiânia - GO, 74675-090',
      'telefone': '(62) 3605-1400',
      'imagemFilial': 'assets/imagens/filial-go.jpg'
    },

    {
      'id': 12,
      'filial': 'FILIAL MINAS GERAIS',
      'endereco': 'Avenida Geraldo Rocha Número 200, Bairro industrial, Contagem - MG, 32183-054',
      'telefone': '(31) 3555-4900',
      'imagemFilial': 'assets/imagens/filial-mg.jpg'
    },
    {
      'id': 5,
      'filial': 'FILIAL PARÁ',
      'endereco': 'Rod BR 316, S/N KM 20 Alianza Park - Canutama, Benevides-PA, 68795-000',
      'telefone': '(91) 4005-3100',
      'imagemFilial': 'assets/imagens/filial-pa.jpg'
    },
    {
      'id': 6,
      'filial': 'FILIAL BAHIA',
      'endereco': 'Via de Penetração - Número 331 - CIA Sul, - Simões Filho-BA, 43700-000',
      'telefone': '(71) 3046-1616',
      'imagemFilial': 'assets/imagens/filial-ba.jpg'
    }
  ];

  constructor(
    private global:GlobalService
  ) { }

  ngOnInit(): void {
    this.global.voltarTopoPagina();
  }

}
