<ngb-carousel #carousel [interval]="5000">
  <ng-template *ngFor="let titulo of titulos; index as i" ngbSlide>
    <a class="row" target="_blank" rel="nofollow noopener noreferrer">
      <div class="picsum-img-wrapper">
        <img class="img-banner" [src]="titulo.backgroundBanner + '.jpg'">
      </div>

      <div class="carousel-caption">
        <img class="img-destaque-banner position-absolute d-xl-block d-lg-block d-md-block d-sm-none d-none"
          [src]="titulo.imgBanner">
        <div class="row align-items-end col-xl-5 col-lg-5 col-md-6 banner-texto">
          <h2>{{titulo.titulo}}</h2>
          <p>{{titulo.subtitulo}}</p>
          <a  [routerLink]="titulo.link" class="col">
            <button class="col-xl-7 col-lg-8 col-md-12">SAIBA MAIS</button>
          </a>
        </div>
      </div>
    </a>
  </ng-template>
</ngb-carousel>
