<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container-fluid mt-5 div-icones-topo">
    <div class="container col-11 corpo">
        <app-buscar-cabecalho></app-buscar-cabecalho>
    </div>

    <div class="container col-11 mt-4">
        <div class="row lista-produtos pl-4 pr-4">
            <ng-container *ngFor="let produto of produtos.produtosLancamentoApi; index as i">
                <a [id]='produto.id'
                    (click)='
                        produtos.ativarNomeProdutoAtivo(produto.id);
                        produtos.exibirModal();
                        produtos.indexClicado(produto.cod_index);
                        '
                    class="col-3 link-produto-unico"
                    [ngClass]="produtos.classeProdutoUnico"
                    [routerLink]="produto.id" >

                    <app-produto-unico
                        [nome]="produto.nome"
                        [fornecedor]="produto.fornecedor"
                        [codFornecedor]="produto.cod_fornecedor"
                        [id]="produto.id">
                    </app-produto-unico>
                </a>
            </ng-container>
        </div>
    </div>

</div>
