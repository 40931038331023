import { Component, Input, OnInit } from '@angular/core';
import { ProdutosService } from '../produtos.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/servicos/global.service';


@Component({
  selector: 'app-produto-modal',
  templateUrl: './produto-modal.component.html',
  styleUrls: ['./produto-modal.component.css']
})
export class ProdutoModalComponent implements OnInit {


  imagemProduto: String;

  filter = new FormControl('');

  constructor(
    public produtos: ProdutosService,
    public modal: NgbActiveModal,
    public global: GlobalService
  ) {
    this.produtos.exibirProdutoNomeAtivo();
  }



  ngOnInit(): void {
    this.produtos.exibirProdutoDescricao();
    this.produtos.exibirProdutoIndex();
    this.produtos.exibirProdutoDescricaoIndex();
    this.produtos.exibirProdutoNomeIndex();
  }
}
