<ul class="row ul-produto-unico">
  <li>
      <div class="col produtos-li">
          <img class="img-fluid" [src]="global.urlImagem(id)">
          <div class="row align-items-center div-nome-produto">
              <h3 class="align-middle col-12">{{nome}}</h3>
          </div>
          <div class="align-items-center div-fornecedor-logo col-12">
              <h4>{{fornecedor}}</h4>
              <img class="img-fornecedor img-fluid col-4" [src]="global.urlLogoFornecedor(codFornecedor)">
          </div>
      </div>
  </li>
</ul>
