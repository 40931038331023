import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colaborador',
  templateUrl: './colaborador.component.html',
  styleUrls: ['./colaborador.component.css']
})
export class ColaboradorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.open('http://rh.novacasadistribuidora.com/framehtml/web/app/RH/PortalMeuRH/#/login');
    history.go(-1);
  }

}
