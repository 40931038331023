import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestor',
  templateUrl: './gestor.component.html',
  styleUrls: ['./gestor.component.css']
})
export class GestorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.open('http://rh.novacasadistribuidora.com/Corpore.Net/Login.aspx');
    history.go(-1);
  }

}
