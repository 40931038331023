import { Component, OnInit } from '@angular/core';
import { LinhasService} from '../produtos/linhas.service';
import { GlobalService } from '../servicos/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {



paginas: any[] = [
  {
    'nome': 'A NOVA CASA',
    'link': '/sobre',
  },
  {
    'nome': 'PARCEIROS',
    'link': '/parceiros',
  },
  {
    'nome': 'PRODUTOS',
    'link': '/produtos',
  },
  {
    'nome': 'LANÇAMENTOS',
    'link': '/produtos/lancamentos',
  },

  {
    'nome': 'CONTATO',
    'link': '/contato',
  },

  {
    'nome': 'PORTAL DO COLABORADOR',
    'link': '/colaboradores'
  },

  {
    'nome': 'PORTAL DO GESTOR',
    'link': '/gestores'
  }



]


date = new Date;
anoAtual = this.date.getFullYear();

  constructor(
    public linhas: LinhasService,
    public global: GlobalService
    ){
     }

  ngOnInit(): void {

    this.linhas.exibirLinha();



  }

}
