<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>
<div class="container-fluid mt-5">
  <div class="container div-icones-topo col-11">
    <app-buscar-cabecalho-lista></app-buscar-cabecalho-lista>
  </div>
  <div class="container col-11 mt-4">

    <div class="row ml-2 mr-2  mb-3 text-right">
      <ng-container *ngFor="let grupo of linhas.grupoLinhaAPI; index as i">
        <h6 *ngIf="i != 0" class="mr-2 ml-2"> | </h6>
        <a>
          <h6 (click)="this.linhas.ativarGrupo(grupo.cod_grupo); this.produtos.exibirProdutosNome(grupo.cod_grupo)"
            [ngClass]="linhas.grupoAtivo == grupo.cod_grupo ?'grupo-subgrupo-selecionado': false">
            {{grupo.grupo}}</h6>
        </a>
      </ng-container>
    </div>

    <div class="row lista-produtos">
      <ng-container *ngFor="let produto of produtos.produtosNomeApi; index as i">
          <a *ngIf="linhas.subGrupoAtivo == produto.cod_subgrupo|| linhas.subGrupoAtivo  == 0"
              [id]='produto.id' (click)='
                  produtos.indexClicado(produto.cod_index);
                  produtos.ativarNomeProdutoAtivo(produto.id);
                  produtos.exibirModal()
                  ' class="link-produto-unico" [ngClass]="produtos.classeProdutoUnico"
              [routerLink]="produto.id">

              <app-produto-unico [nome]="produto.nome" [fornecedor]="produto.fornecedor"
                  [codFornecedor]="produto.cod_fornecedor" [id]="produto.id">
              </app-produto-unico>
          </a>
      </ng-container>
  </div>

  </div>
</div>
