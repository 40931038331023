import { Component, OnInit } from '@angular/core';
import { ProdutosService } from '../produtos/produtos.service';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ResponsivoService } from '../servicos/responsivo.service';

@Component({
  selector: 'app-produto-destaque-index',
  templateUrl: './produto-destaque-index.component.html',
  styleUrls: ['./produto-destaque-index.component.css']
})

export class ProdutoDestaqueIndexComponent implements OnInit {
  tituloPaginas = 'LANÇAMENTOS';
  corTitulo     = 'produto-lancamento';
  corTituloSvg  = 'produto-lancamento-svg'

  constructor(
    public produtos:ProdutosService,
    public responsivo:ResponsivoService
  ) {

  }
  ngOnInit(): void {
    this.produtos.exibirProdutoLancamentoIndex();
  }

}
