import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-banner',


  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],

})

export class BannerComponent implements OnInit {
  linkBanner = 'assets/imagens/banners/';
  
  status = 'active';

  titulos: any[] = [

    {
      "imgBanner": null,
      "titulo": "STARRETT É PRA TODA OBRA!",
      "subtitulo": "As melhores ferramentas de medição e corte. Conheça!",
      "link": "/produtos/fornecedores/22",
      "backgroundBanner": this.linkBanner + "starrett",
      "status" : this.status
    },

    {
      "imgBanner": this.linkBanner + 'img_pado.png',
      "titulo": "SEGURANÇA É COM A PADO!",
      "subtitulo": "Cadeados, fechaduras e muito mais no mix Pado",
      "link": "/produtos/fornecedores/205",
      "backgroundBanner": this.linkBanner + "bg_pado",
      "status" : this.status
    },

    {
      "imgBanner": this.linkBanner + 'img_banner1.png',
      "titulo": "CATÁLOGO DE PRODUTOS",
      "subtitulo": "Conheça nosso mix com mais de 13.000 itens",
      "link": "/produtos",
      "backgroundBanner": this.linkBanner + "banner_nc",
      "status" : this.status
    },
    // {
    //   "imgBanner": this.linkBanner + 'img_banner2.png',
    //   "titulo": "4º RANKING ANAMACO",
    //   "subtitulo": "Eleita ano após ano entre as maiores distribuidoras do Brasil",
    //   "link": "*",
    //   "backgroundBanner": this.linkBanner + "banner_nc2",
    //   "status" : this.status
    // },

    // {
    //   "imgBanner": null,
    //   "titulo": "PORTAL DO COLABORADOR",
    //   "subtitulo": "Solicitações de férias, banco de horas e muito mais",
    //   "link": "/colaboradores",
    //   "backgroundBanner": this.linkBanner + "funcionarios",
    //   "status" : this.status
    // },
  ]


  constructor() { }

  ngOnInit(): void {

  }

}
