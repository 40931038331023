import { Component, OnInit } from '@angular/core';
import { ProdutosService } from 'src/app/produtos/produtos.service';
import { ResponsivoService } from 'src/app/servicos/responsivo.service';

@Component({
  selector: 'app-menu-responsivo',
  templateUrl: './menu-responsivo.component.html',
  styleUrls: ['./menu-responsivo.component.css']
})
export class MenuResponsivoComponent implements OnInit {

  trabalheConosco = 'http://177.69.181.67/framehtml/RM/Rhu-BancoTalentos/#/RM/Rhu-BancoTalentos/home';

  constructor(
    public produtos:ProdutosService,
    public responsivo: ResponsivoService,

  ) { }

  ngOnInit(): void {
  }

}
