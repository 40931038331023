<button type="button" class="close p-2" aria-label="Close" (click)="modal.close()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="corpo-modal">
  <div class="">
    <div class="row justify-content-between corpo-produto">

      <h3 class="col-12 ml-2 mt-5 mb-5 pb-3 ">CADASTRO DE PRODUTOS</h3>
      <h3 class="col-12">Grupo Ativo {{linhas.grupoAtivo}}</h3>
      <!-- <h3 class="col-12">Grupo Ativo{{linhas.grupoAtivo}}</h3>
      <h3 class="col-12">Subgrupo Ativo{{linhas.subGrupoAtivo}}</h3> -->

      <div class="imagem-produto col-xl-5 col-12 pr-xl-4 pr-0 mb-2">
        <img *ngFor="let produto of produtos.produtosNomeApiAtivo" class="logo col-3"
          [src]="global.urlLogoFornecedor(produto.cod_fornecedor)">


        <ng-container *ngIf="produtos.produtosDescricaoApiIndex.length > 1  then carrousel else imagemUnica">
        </ng-container>

        <!-- template para escolher quantidade de imagens, caso seja mais que uma abrirá um carroseu -->
        <ng-template #carrousel>
          <ngb-carousel class="col">
            <ng-container *ngFor="let imagemProdutos of produtos.produtosDescricaoApiIndex; index as i">
              <ng-template ngbSlide>
                <ng-container>
                  <img class="img-fluid" [src]="global.urlImagem(imagemProdutos.cod_nc_img)">
                </ng-container>
              </ng-template>
            </ng-container>
          </ngb-carousel>
        </ng-template>

        <ng-template #imagemUnica>
          <div class="col">
            <img class="img-fluid" [src]="global.urlImagem(produtos.produtoAtivo)">
          </div>
        </ng-template>
        <!-- --------------------------------------------------------------------------------------- -->

      </div>
      <div class="col-7">
        <form [formGroup]="nomeProduto" (ngSubmit)="insertOrUpdateProdutos(nomeProduto)">
          Form: {{nomeProduto.value | json}}


          <button type="submit" class="button"> Enviar</button>


          <ng-container *ngFor="let produto of produtos.produtosNomeApiAtivo">
            <mat-form-field class="col-12">
              <mat-label>Index</mat-label>
              <mat-select [(value)]="produto.cod_index" matNativeControl required formControlName="cod_index">
                <mat-option *ngFor="let index of produtos.indexAPI" [value]="index.id">
                  {{index.nome_index}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>ID Produto Principal</mat-label>
              <input formControlName="id" matInput [value]="produto.id">
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>ID Imagem Alternativa</mat-label>
              <input formControlName="cod_img_alt" matInput [value]="produto.cod_img_alt">
              <mat-hint>Imagem alternativa para capa</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Nome</mat-label>
              <input required formControlName="nome" matInput [value]="produto.nome">
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Linha</mat-label>
              <mat-select formControlName="cod_linha" (selectionChange)="linhas.exibirGrupoLinha(produto.cod_linha)" [(value)]="produto.cod_linha" matNativeControl required>
                <mat-option *ngFor="let linha of linhas.linhasAPI" [value]="linha.id">{{linha.nome}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Grupo</mat-label>
              <mat-select formControlName="cod_grupo" (selectionChange)="linhas.exibirSubGrupo(produto.cod_grupo)" [(value)]="produto.cod_grupo" matNativeControl required>
                <mat-option *ngFor="let grupo of linhas.grupoLinhaAPI" [value]="grupo.cod_grupo">{{grupo.grupo}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Subgrupo</mat-label>
              <mat-select formControlName="cod_subgrupo" [(value)]="produto.cod_subgrupo" matNativeControl required>
                <mat-option *ngFor="let subgrupo of linhas.subGruposAPI" [(value)]="subgrupo.id" >{{subgrupo.subgrupo}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Fornecedores</mat-label>
              <mat-select formControlName="cod_fornecedor" [(value)]="produto.cod_fornecedor" matNativeControl required>
                <mat-option [value]="fornecedor.cod_fornecedor" *ngFor="let fornecedor of fornecedores.fornecedoresAPI">{{fornecedor.fornecedor}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Observações gerais</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>

          </ng-container>
          
        </form>

      </div>
      <div class="col-12">
        <div class="table-responsive-lg">
          <!-- <h1 *ngFor="let produto of produtos.produtosNomeApiAtivo">{{produto.nome}}</h1> -->

          <table class="table table-sm table-borderless table-striped table-hover col">
            <thead class="thead-dark">
              <tr>
                <th>CÓD</th>
                <th>DESCRIÇÃO</th>
                <th>ORDENAR</th>
                <th>ORDENAR</th>
                <th>ORDENAR</th>
                <th>COD_IMG</th>
                <th>REF.FÁBRICA</th>
                <th>EAN</th>
                <!-- <th>EMB</th> -->
                <th>DESCRIÇÃO DO WINTRHOR</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let produto of produtos.produtosDescricaoApi">
                <tr>
                  <td scope="row">
                    <input class="form-control" size="4" [value]="produto.id">
                  </td>
                  <td>
                    <input class="form-control" size="30" [value]="produto.descricao">
                  </td>
                  <td>
                    <input class="form-control" size="5" [value]="produto.ordenar">
                  </td>
                  <td>
                    <input class="form-control" size="5" [value]="produto.ordenar2">
                  </td>
                  <td>
                    <input class="form-control" size="5" [value]="produto.ordenar3">
                  </td>
                  <td>
                    <input class="form-control" size="4" [value]="produto.cod_nc_img">
                  </td>
                  <td class="align-middle">{{produto.codfab}}</td>
                  <td class="align-middle">{{produto.ean}}</td>
                  <!-- <td class="align-middle">{{produto.multiplo}} {{produto.unidade}}</td> -->
                  <td class="align-middle">{{produto.descricao_winthor}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <app-produto-semelhante></app-produto-semelhante>
  </div>

</div>
