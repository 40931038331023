<div class="col-9 menu-responsivo position-fixed" *ngIf="responsivo.statusMenu == true">
    <ul id="header-menu" class="pt-3 pl-2">
      <button type="button" class="close mt-3 mb-4" (click)="responsivo.mudarStatusMenu(false)">
        <span aria-hidden="true">&times;</span>
      </button>

      <form id="header-regiao-atendida" class="mb-5">
        <label class="mr-2">SUA REGIÃO:</label>
        <select
          class="form-control" id="exampleFormControlSelect1"
          name="filialAtiva"
          [(ngModel)]="responsivo.filialAtiva"
          (change)="produtos.adicionarSessaoFilialAtiva(responsivo.filialAtiva)"
        >
          <option *ngFor="let regiaoAtendida of responsivo.regioesAtendidas" [value]="regiaoAtendida.id">
            {{regiaoAtendida.nome}}
          </option>
        </select>
      </form>

      <li class="pb-3 pl-3" *ngFor="let menu of responsivo.menuLista; index as i; count as c">
        <a [routerLink]="menu.link" (click)="responsivo.mudarStatusMenu(false)">
          <div class="row align-items-center">
            <img class="icone-menu mr-4" [src]="menu.linkImg+'-responsivo.svg'" [alt]="menu.nome">
            <h1 class="mt-2">{{menu.nome}}</h1>
          </div>
        </a>
      </li>

      <li class="pb-3 pl-3">
        <a [href]="trabalheConosco">
          <div class="row align-items-center">
            <img class="icone-menu mr-4" [src]="'assets/imagens/header-trabalhe-conosco-responsivo.svg'">
            <h1 class="mt-2">Trabalhe Conosco</h1>
          </div>
        </a>
      </li>
    </ul>
  </div>
