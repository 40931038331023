import { Injectable, OnDestroy, Input, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProdutoModalComponent } from './produto-modal/produto-modal.component';
import { GlobalService } from '../servicos/global.service';
import { ProdutoModalSistemaComponent } from './produto-modal-sistema/produto-modal-sistema.component';

@Injectable({
  providedIn: 'root'
})

export class ProdutosService implements OnDestroy {
  destroy = new Subject<any>();
  currentDialog = null;


  filialLocal = '99';


  // ROTAS API locais--------------------------------------------------------------
  produtosNomeURL           = this.global.baseUrl + 'produtosnome/';
  produtosNomeIndexURL      = this.global.baseUrl + this.filialLocal + '/produtosnomeindex/';
  produtosDescricaoURL      = this.global.baseUrl + this.filialLocal + '/produtosdescricao/';
  produtosDescricaoIndex    = this.global.baseUrl + this.filialLocal + '/produtosdescricaoindex/';
  produtosIndexUrl          = this.global.baseUrl + 'produtosindex/';
  produtosGrupoURL          = this.global.baseUrl + this.filialLocal + '/produtosnomegrupo/';
  produtosLinhaURL          = this.global.baseUrl + this.filialLocal + '/produtosnomelinha/';
  produtosBuscarURL         = this.global.baseUrl + this.filialLocal + '/buscar/';
  produtosWinthorURL        = this.global.baseUrl + 'winthor-produtos/';
  produtosLancamentos       = this.global.baseUrl + 'lancamentos/'
  produtosLancamentosIndex  = this.global.baseUrl + 'lancamentos-index/'
  produtosNomeFornecedorURL = this.global.baseUrl + this.filialLocal + '/produtosfornecedor/'
  IndexURL                  = this.global.baseUrl + 'produtosindex';

  // ---------------------------------------------------------------------------------

  // informa o item clicado no momento ou ativo na tela
  grupoAtivo: number;
  linhaAtiva: number;
  subGrupoAtivo: number;
  produtoAtivo: number;
  indexAtivo: number;
  valorBusca:String;
  buscarProduto:String;
  idWinthor:number;
  fornecedorAtivo:number;
  // -----------------------------

  // objetos criados para o NG For
  produtosNomeApi: any = [];
  produtosNomeApiAtivo: any = [];
  produtosNomeApiLinha: any = [];
  produtosDescricaoApi: any = [];
  produtosDescricaoApiIndex: any = [];
  produtosIndexApi: any = [];
  produtosNomeIndex: any = [];
  produtosImagens: any = [];
  produtosBusca: any = [];
  buscaApi: any = [];
  buscaApiFornecedor: any = [];

  produtosLancamentoApi: any = [];
  produtosLancamentosIndexApi: any = [];

  produtoWinthor: any = [];

  indexAPI: any = [];

  produtoWinthorPush: any = [];
  sessaoAtiva: any = [];

  // -----------------------------

  urlImagensProdutos:String     = 'http://www.novacasadistribuidora.com/links/imagens/produtos_alta_resolucao/';
  urlImagensFornecedores:String = 'http://www.novacasadistribuidora.com/links/imagens/fornecedores/fornecedores_produtos/';
  // Carregar Rotas Backend



  id:number;
  nome:string;
  cod_grupo:number;
  cod_subgrupo:number;
  cod_index:number;
  cod_fornecedor:number;
  cod_linha:number;
  cod_img_alt:number;

  classeProdutoUnico = 'col-xl-3 col-lg-4 col-md-6 col-12';

  exibirProdutosNome(id = this.grupoAtivo) {
    this.http
      .get(this.produtosGrupoURL + id)
      .subscribe(data => this.produtosNomeApi = data)
  }

  exibirProdutosNomeLinha(id = this.linhaAtiva) {
    this.http
      .get(this.produtosLinhaURL + id)
      .subscribe(data => this.produtosNomeApiLinha = data)
  }

  exibirProdutosNome2(id) {
    this.http
      .get(this.produtosGrupoURL + id)
      .subscribe(data => this.produtosNomeApi = data)
  }


  exibirProdutoDescricao() {
    this.http
      .get(this.produtosDescricaoURL +  this.produtoAtivo)
      .subscribe(data => this.produtosDescricaoApi = data);
  }

  exibirProdutoNomeAtivo() {
    this.http
      .get(this.produtosNomeURL + this.produtoAtivo)
      .subscribe(data =>
        {
          this.produtosNomeApiAtivo = data;
        }
       )
        
  }

  exibirProdutoNomeIndex() {
    this.http
      .get(this.produtosNomeIndexURL + this.indexAtivo)
      .subscribe(data => this.produtosNomeIndex = data)
  }

  exibirIndex() {
    this.http
      .get(this.IndexURL)
      .subscribe(data => this.indexAPI = data)
  }

  exibirProdutoIndex() {
    this.http
      .get(this.produtosIndexUrl)
      .subscribe(data => this.produtosIndexApi = data)
  }

  exibirProdutoDescricaoIndex() {
    this.http
      .get(this.produtosDescricaoIndex + this.produtoAtivo)
      .subscribe(data => this.produtosDescricaoApiIndex = data);
  }

  buscarProdutos(){
    this.http
      .get(this.produtosBuscarURL + this.buscarProduto)
      .subscribe(data => this.buscaApi = data)
      console.log("Ativar produto");
  }

  buscarProdutosFornecedor(){
    this.http
      .get(this.produtosNomeFornecedorURL + this.fornecedorAtivo)
      .subscribe(data => this.buscaApiFornecedor = data)

      console.log(this.produtosNomeFornecedorURL + this.fornecedorAtivo);
  }

  exibirProdutoWinthor(){
    this.http
    .get(this.produtosWinthorURL + this.idWinthor)
    .subscribe(data => this.produtoWinthor = data)
  }


  exibirProdutoLancamento(){
    this.http
    .get(this.produtosLancamentos)
    .subscribe(data => this.produtosLancamentoApi = data)
  }


  exibirProdutoLancamentoIndex(){
    this.http
    .get(this.produtosLancamentosIndex)
    .subscribe(data => this.produtosLancamentosIndexApi = data)
  }


  indexClicado(id_index) {
    this.indexAtivo = id_index;
  }


  ativarNomeProdutoAtivo(produtoAtivo){
    this.produtoAtivo = produtoAtivo;
  }

  // exibe o modal produtos em qualquer lugar chamado
  exibirModal() {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      this.currentDialog = this.modalService.open(ProdutoModalComponent, { centered: true, size: 'xl' });

      this.currentDialog.result.then(result => {
        history.go(-1);
      }, reason => {
        history.go(-1);
      });
    });
  }

  exibirModalSemRota(produtoAtivo, fornecedorAtivo){
    this.produtoAtivo = produtoAtivo;
    this.fornecedorAtivo = fornecedorAtivo;
    this.exibirProdutoDescricao();
    this.exibirProdutoIndex();
    this.exibirProdutoDescricaoIndex();
    this.exibirProdutoNomeIndex();
    this.exibirProdutoNomeAtivo();
  }


  adicionarSessaoFilialAtiva(filialAtiva){
    // sessionStorage.setItem('filial', filialAtiva);
    this.filialLocal = filialAtiva;
    this.ativarUrlFilial(filialAtiva);
  }

  ativarUrlFilial(filialLocal){
    this.produtosNomeIndexURL      = this.global.baseUrl + filialLocal + '/produtosnomeindex/';
    this.produtosDescricaoURL      = this.global.baseUrl + filialLocal + '/produtosdescricao/';
    this.produtosDescricaoIndex    = this.global.baseUrl + filialLocal + '/produtosdescricaoindex/';
    this.produtosGrupoURL          = this.global.baseUrl + filialLocal + '/produtosnomegrupo/';
    this.produtosBuscarURL         = this.global.baseUrl + filialLocal + '/buscar/';
    this.produtosNomeFornecedorURL = this.global.baseUrl + filialLocal + '/produtosfornecedor/'
  }

  verificarEstadoSessaoFilial(){
    if(sessionStorage.getItem('filial') == null){
      this.filialLocal = '99';

      sessionStorage.setItem('filial', '99');
    }
    else{
    }

  }



  carregarProdutoForm(){
    for (const produto of this.produtosNomeApiAtivo) {
      this.id = produto.id;
      this.nome = produto.nome;
    }
  }

  ngOnDestroy() {

  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    public router: Router,
    private global: GlobalService
  ) {

    this.verificarEstadoSessaoFilial();


  }
}
