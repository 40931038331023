import { Component, OnInit } from '@angular/core';
import { FornecedoresService } from '../fornecedores.service';

@Component({
  selector: 'app-index-pagina',
  templateUrl: './index-pagina.component.html',
  styleUrls: ['./index-pagina.component.css']
})
export class IndexPaginaComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit(): void {
  }

}
