import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProdutosService } from '../produtos/produtos.service';
import { ResponsivoService } from '../servicos/responsivo.service';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})

export class CabecalhoComponent implements OnInit {
  
  regioesAtendidas: any[] = [
    {
      "nome": "DF, TO, PA",
      "id": "2"
    },
    {
      "nome": "MINAS GERAIS",
      "id": "12"
    },
    {
      "nome": "GOIÁS",
      "id": "4"
    },
    {
      "nome": "TODAS",
      "id": "99"
    },

  ]


  filialAtiva: any = '99';

  latitude: string;
  longitude: string;

  localizacao: any = [];


  ngOnInit(): void {
    this.filialAtiva = this.produtos.filialLocal;
    this.produtos.filialLocal = this.filialAtiva;
  }

  constructor(
    public http: HttpClient,
    public produtos: ProdutosService,
    public responsivo: ResponsivoService

  ) {


  }

}
