<app-titulo-paginas [tamanhoColuna]="'col-10'" [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo"
  [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container-fluid produtos-destaque-background  mt-5 pb-5">
  <div id="carouselLinhaIndex" class="carousel slide col-12" data-ride="carousel">
    <ol class="carousel-indicators">
      <ng-container *ngFor="let slide of linhas.qtdPagCarroselLinha; index as i">
        <li data-target="#carouselLinhaIndex" data-slide-to="i" [ngClass]="i == 0? 'active':false"></li>
      </ng-container>
    </ol>

    <div class="col-10 container carousel-inner">
      <ng-container *ngFor="let itens of linhas.qtdPagCarroselLinha; index as i0">
        <div class="col carousel-item" [ngClass]="i0 == 0? 'active':false">
          <div class="row">
            <ng-container *ngFor="let linha of linhas.linhasAPI; index as i">
              <li class="col-xl-2 col-lg-2 col-md-3 col-sm-6" *ngIf="i < (i0+1)*linhas.qtdColCarroselLinha && (i0)*linhas.qtdColCarroselLinha <= i">
                <a [routerLink]="'produtos/linha/' + linha.id" (click)="global.voltarTopoPagina(); linhas.ativarGrupo(undefined)">
                  <div class="produtos-li">
                    <div class="align-middle pt-2">
                      <h3 class="col">{{linha.nome}}</h3>
                    </div>
                    <div class="mesclagem-imagem">
                      <img class="col img-produto" [src]="'assets/imagens/img-linhas/index/'+linha.id + '.jpg'">
                    </div>
                  </div>
                </a>
              </li>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <a class="carousel-control-prev" href="#carouselLinhaIndex" role="button" data-slide="prev">
      <span class="" aria-hidden="true"><img src="assets/imagens/seta_esquerda_linha.svg"></span>
    </a>
    <a class="carousel-control-next" href="#carouselLinhaIndex" role="button" data-slide="next">
      <span class="" aria-hidden="true"><img src="assets/imagens/seta_direita_linha.svg"></span>
    </a>
  </div>
</div>
