import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { LinhasService } from '../linhas.service';
import { ProdutosService } from '../produtos.service';

@Component({
  selector: 'app-produto-menu',
  templateUrl: './produto-menu.component.html',
  styleUrls: ['./produto-menu.component.css']
})

export class ProdutoMenuComponent implements OnInit {

  tituloPaginas = 'PRODUTOS';
  corTitulo = "produto-linha";
  corTituloSvg = "produto-linha-svg";

  @Input() linhaAtiva: number;

  


  ngOnInit(): void {
    // this.linhas.exibirGrupoAtivo();
    this.linhas.exibirLinha();
    this.linhas.exibirGrupo();
    this.linhas.linhaClicado = 0;
    
   
  }


  ativarProdutoGrupo(idGrupo, idLinha){
    this.produtos.exibirProdutosNome(idGrupo);
    this.linhas.exibirLinhaAtiva(idLinha);
    this.linhas.exibirGrupoAtivo(idGrupo);
    this.linhas.exibirSubGrupo(idGrupo);

    // Uso esses dois parametros para fechar o menu
    this.linhas.cliques = 0;
    this.linhas.linhaClicado = 0;

  }

  constructor(
    public linhas: LinhasService,
    public produtos: ProdutosService
    
    ){
      this.linhas.paginaAtiva = false;
      
  }

}
