<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container-fluid div-fundo">
    <div class="col-11 container pb-5">
        <div class="div-cinza d-xl-block d-lg-block d-none"></div>
        <div class="row pt-5">
            <div class="col-xl-5 col-lg-5 col-12">
                <div class="col-10 text-center">
                    <img class="mb-3" src="assets/imagens/icone-estrela.svg">
                    <h3 class="mb-3">FORNECEDORES <br> EXCLUSIVOS</h3>
                    <p class="p-principal mb-3">Fornecedores que você só vai encontrar na Nova Casa</p>
                    <p class="p-informativo">Em alguns casos a exclusividade não é estendida para todas as regiões</p>
                </div>
            </div>
            <div class="col">
                <ul class="row logo-fornecedores">
                    <ng-container *ngFor="let fornecedor of fornecedores.fornecedoresAPI">
                        <a [routerLink]="'/produtos/fornecedores/' + fornecedor.cod_fornecedor"
                            class="col-xl-3 col-lg-4 col-md-4 col-6 p-0 pr-4 mb-4"
                            *ngIf="fornecedor.status == 'E'">
                            <div>
                                <li class="pt-1 pb-2">
                                    <img class="img-fluid col-12"
                                        [src]="'assets/imagens/fornecedores/logo-media/'+fornecedor.cod_fornecedor+'.png'">
                                </li>
                            </div>
                        </a>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-11 container mt-5">
        <div class="row pt-3 pb-3">
            <div class="div-cinza2 d-xl-block d-lg-block d-none"></div>

            <ng-template *ngIf="responsivo.breakpointAtivo == 'MD' || responsivo.breakpointAtivo == 'SM'
                then fornecedoresParceiros
                else fornecedoresParceirosLogo">
            </ng-template>

            <ng-template *ngIf="responsivo.breakpointAtivo == 'MD' || responsivo.breakpointAtivo == 'SM'
                then fornecedoresParceirosLogo
                else fornecedoresParceiros">
            </ng-template>
        </div>
    </div>
</div>

<!-- Inserir fornecedores conforme orientação -->
<ng-template #fornecedoresParceirosLogo>
    <div class="col">
        <ul class="row logo-fornecedores align-items-center">
            <ng-container *ngFor="let fornecedor of fornecedores.fornecedoresAPI">
              <a [routerLink]="'/produtos/fornecedores/' + fornecedor.cod_fornecedor"
              *ngIf="fornecedor.status == 'P'"
              class="col-xl-3 col-lg-4 col-md-4 col-6 p-0 pr-4 mb-4">
                <div>
                    <li class="pt-1 pb-2">
                        <img class="img-fluid col-12"
                            [src]="'assets/imagens/fornecedores/logo-media/'+fornecedor.cod_fornecedor+'.png'">
                    </li>
                </div>
              </a>
            </ng-container>
        </ul>
    </div>
</ng-template>
<ng-template #fornecedoresParceiros>
    <div class="col-xl-5 col-lg-5 col-12">
        <div class="col-10 text-center">
            <img class="mb-3" src="assets/imagens/icone-parceiros.svg">
            <h3 class="mb-3">FORNECEDORES <br> PARCEIROS</h3>
            <p class="p-principal mb-3">Fornecedores com as melhores condições do mercado</p>
        </div>
    </div>
</ng-template>
