<!DOCTYPE html>
<html lang="pt">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
  <title>Document</title>
</head>

<body>
  <app-menu-responsivo></app-menu-responsivo>
  <div [ngClass]="responsivo.statusMenu ? 'div-componentes' : 'div-componentesInativo'" (click)="responsivo.mudarStatusMenu(false, true)">
    <app-cabecalho></app-cabecalho>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
  
</body>

</html>