import { Component, OnInit } from '@angular/core';
import { FornecedoresService } from '../fornecedores.service';
import { ResponsivoService } from '../servicos/responsivo.service';

@Component({
  selector: 'app-fornecedores-index',
  templateUrl: './fornecedores-index.component.html',
  styleUrls: ['./fornecedores-index.component.css']
})
export class FornecedoresIndexComponent implements OnInit {

  constructor(
    public fornecedores:FornecedoresService,
    public responsivo:ResponsivoService
  ) {

    this.fornecedores.exibirFornecedoresDestaque();
  }

  ngOnInit(): void {

  }

}
