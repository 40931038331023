import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { range } from 'rxjs';
import { FornecedoresService } from '../fornecedores.service';
import { LinhasService } from '../produtos/linhas.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsivoService {

  verificarTamanhoAtualTela: any = [];
  breakpointAtivo: string = 'XL';

  qtdColCarrosel: number = 4;
  
  qtdPagCarrosel: any = [1, 2, 3, 4];



  filialAtiva: any = '99';

  statusMenu: boolean = false;

  menuLista: any[] = [
    {
      "nome": "Parceiros",
      "linkImg": "assets/imagens/header-parceiros",
      "link": "parceiros"
    },
    {
      "nome": "A Nova Casa",
      "linkImg": "assets/imagens/header-nc",
      "link": "sobre"
    },
    {
      "nome": "Sobre o G8",
      "linkImg": "assets/imagens/header-g8",
      "link": "g8"
    },
    {
      "nome": "Produtos",
      "linkImg": "assets/imagens/header-produtos",
      "link": "produtos"
    },
    {
      "nome": "Contato",
      "linkImg": "assets/imagens/header-contato",
      "link": "contato"
    },
  ]


  regioesAtendidas: any[] = [
    {
      "nome": "DF, TO, PA",
      "id": "2"
    },
    {
      "nome": "MINAS GERAIS",
      "id": "12"
    },
    {
      "nome": "GOIÁS",
      "id": "4"
    },
    {
      "nome": "TODAS",
      "id": "99"
    },
  ]

  outrosContatos: any[] = [
    {
      "nome": "TRABALHE CONOSCO",
      "link": "http://rh.novacasadistribuidora.com/framehtml/RM/Rhu-BancoTalentos/#/RM/Rhu-BancoTalentos/home"
    }
  ]

  mudarStatusMenu(status, divComponente?) {
    if (this.statusMenu == true && divComponente == true) {
      return;
    }
    else {
      this.statusMenu = status;
    }

    console.log("O Status foi mudado para " + this.statusMenu);
  }

  breakpointXL() {
    let tamanhoTela = this.breakpointObserver.isMatched('(max-width: 1200px)');
    if (tamanhoTela == true) {
      this.breakpointAtivo = 'XL';
      this.qtdColCarrosel = 3;
      this.qtdPagCarrosel = [1, 2, 3, 4, 5]

      this.linhas.qtdPagCarroselLinha = [];

      // this.linhas.qtdColCarroselLinha = 5;
      // this.linhas.qtdPagCarroselLinha = [];
    }

    // this.breakpointObserver.observe([
    //   '(max-width: 1200px)',
    // ])
    //   .subscribe(result => {
    //     if (result.matches = true) {
    //       this.breakpointAtivo = 'XL';
    //       this.qtdColCarrosel = 4;
    //       this.qtdPagCarrosel = [1, 2, 3, 4]
    //     }
    //   });
  }

  breakpointLG() {
    let tamanhoTela = this.breakpointObserver.isMatched('(max-width: 992px)');
    if (tamanhoTela == true) {
      this.breakpointAtivo = 'LG';
      this.qtdColCarrosel = 3;
      this.qtdPagCarrosel = [1, 2, 3, 4, 5]
      this.fornecedores.qtdColCarroselFornecedores = 4;
      this.fornecedores.qtdCarroselFornecedor = [];

      this.linhas.qtdColCarroselLinha = 4;
    }
  }

  breakpointMD() {
    let tamanhoTela = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (tamanhoTela == true) {
      this.breakpointAtivo = 'MD';
      this.qtdColCarrosel = 2;
      this.qtdPagCarrosel = [1, 2, 3, 4, 5, 6, 7, 8];
      this.linhas.qtdColCarroselLinha = 2;
      this.linhas.qtdPagCarroselLinha = [];

      this.fornecedores.qtdColCarroselFornecedores = 3;
    }
  }

  breakpointSM() {
    let tamanhoTela = this.breakpointObserver.isMatched('(max-width: 576px)');
    if (tamanhoTela == true) {
      this.breakpointAtivo = 'SM';
      this.qtdColCarrosel = 1;
      this.qtdPagCarrosel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

      this.fornecedores.qtdColCarroselFornecedores = 2;
      this.fornecedores.qtdCarroselFornecedor = [];

      this.linhas.qtdColCarroselLinha = 1;
    }
  }

  constructor(
    public breakpointObserver: BreakpointObserver,
    protected linhas: LinhasService,
    protected fornecedores: FornecedoresService
  ) {

    this.breakpointXL();
    this.breakpointLG();
    this.breakpointMD();
    this.breakpointSM();

  }
}
