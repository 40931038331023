<header class="container-fluid" id="cabecalho">
  <div class="row">
    <div class="container col-11">
      <div class="row justify-content-between">
        <a [routerLink]="'/'" class="mt-3 mb-3">
          <ng-template
            *ngIf="responsivo.breakpointAtivo =='XL' || responsivo.breakpointAtivo =='LG'
            then logoTradicional
            else logoAlternativa">
          </ng-template>
        </a>
        <!-- {{responsivo.breakpointAtivo}} -->

        <ng-template
          *ngIf="responsivo.breakpointAtivo =='XL' || responsivo.breakpointAtivo =='LG'
          then menuTradicional
          else botaoMenuResponsivo">
        </ng-template>
      </div>
    </div>
  </div>
</header>


<!----------------------------- Template Logo ----------------------------->
<ng-template #logoTradicional>
  <img class="img-fluid" src="assets/imagens/logo.svg" alt="logo nova casa">
</ng-template>

<ng-template #logoAlternativa>
  <img class="img-fluid" src="assets/imagens/logo-variacao.svg" alt="logo nova casa">
</ng-template>





<!----------------------------- Template Menu ----------------------------->
<ng-template #menuTradicional>
  <ul #menuTradicional id="header-menu" class="pt-5">
    <div class="row">
      <li class="pl-4 pt-3" *ngFor="let menu of responsivo.menuLista">
        <a [routerLink]="menu.link">
          <img class="" [src]="menu.linkImg+'.svg'" [alt]="menu.nome">
          <h1>{{menu.nome | uppercase}}</h1>
        </a>
      </li>
    </div>
  </ul>
  <div class="row justify-content-between header-outros-regiao mt-2">
    <ul id='header-outros-contatos' class="mr-5">
      <a *ngFor="let outroContato of responsivo.outrosContatos" [href]="outroContato.link">
        <li class="mt-1">{{outroContato.nome}}</li>
      </a>
    </ul>
    <form id="header-regiao-atendida" class="ml-5">
      <label class="mr-2">SUA REGIÃO:</label>
      <select name="filialAtiva" [(ngModel)]="filialAtiva" (change)="produtos.adicionarSessaoFilialAtiva(filialAtiva)">
        <option *ngFor="let regiaoAtendida of regioesAtendidas" [value]="regiaoAtendida.id">
          {{regiaoAtendida.nome}}
        </option>
      </select>
    </form>
  </div>
</ng-template>

<ng-template #botaoMenuResponsivo>
  <div class="pt-1 pl-2 pr-2 icone-menu position-absolute" (click)="responsivo.mudarStatusMenu(true)">
    <i class="material-icons md-64">reorder</i>
  </div>
</ng-template>