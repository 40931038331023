<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>
<div class="container-fluid mt-5">
    <div class="container col-xl-11 col-lg-11 col-12 div-icones-topo">
        <app-buscar-cabecalho></app-buscar-cabecalho>

        <div class="col mt-xl-0 mt-lg-0 mt-4 p-xl-4 p-lg-4 p-0">
            <div class="row">
                <app-produto-menu class="col"></app-produto-menu>
                <div class="col-6 d-xl-block d-lg-block d-none">
                    <div class="h-100" *ngIf="linhas.linhaClicado != 0; else blocoElse">
                        <img class="imagem-linha" [src]="'assets/imagens/img-linhas/' + linhas.linhaClicado + '.jpg'">
                    </div>
                    <ng-template #blocoElse>
                        <img class="imagem-linha"
                            [src]="'assets/imagens/img-linhas/' + linhas.linhasAPI[0].id + '.jpg'">
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>