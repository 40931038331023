<div class="container imagem" style="max-width: 100%; background-image: url(https://www.novacasadistribuidora.com/assets/imagens/fundo.jpg);">

<!-- DIV FILIAIS -->
<div>
  <h2 class="title">Conheça nossas Filiais</h2>
</div>

<div class="container header col-11 mt-5 mb-5">
      <div class="row justify-content-center">
        <ul class="col-lg-4 mb-3 zoom" style="flex: 0 0 20%; max-width: 20%;" *ngFor="let filial of filiais">
          <li>
            <img class="img-filial img-fluid" src="{{filial.img}}">
            <h3>{{filial.nome}}</h3>
            <ul>
              <li class="mt-2" *ngFor="let atributo of filial.atributos"><img class="mr-1"
                  src="assets/imagens/checkFilial.svg">{{atributo}}</li>
            </ul>
            <!-- <a>
              <button class="col-7 mt-4">SAIBA MAIS</button>
            </a> -->
        </ul>
      </div>
  </div>
</div>
