import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rcas',
  templateUrl: './rcas.component.html',
  styleUrls: ['./rcas.component.css']
})
export class RcasComponent implements OnInit {

  tituloPaginas = 'tituloPaginas';
  corTitulo = 'corTitulo';
  corTituloSvg = 'corTituloSvg';

  constructor() { }

  ngOnInit(): void {
  }

}
