import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/servicos/global.service';

@Component({
  selector: 'app-produto-unico',
  templateUrl: './produto-unico.component.html',
  styleUrls: ['./produto-unico.component.css']
})
export class ProdutoUnicoComponent implements OnInit {
  @Input()
  nomeFornecedor: String;

  @Input()
  codFornecedor: number;

  @Input()
  nome: String;

  @Input()
  id: number;

  @Input()
  fornecedor: String;

  constructor(
    public global:GlobalService
    ) {

     }


  ngOnInit(): void {

  }

}

