import { BreakpointObserver } from '@angular/cdk/layout';
import { Component} from '@angular/core';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { ProdutosService } from './produtos/produtos.service';
import { ResponsivoService } from './servicos/responsivo.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})

export class AppComponent {
  title = 'Nova Casa Distribuidora';


  latitude:String;
  longitude:String;

  exibirPosicao(position) {
    let latitude:string = position.coords.latitude;
    let longitude:string = position.coords.longitude;

    console.log(latitude);
    console.log(longitude);

    alert(latitude + '   |   ' + longitude);


  }


  verificarLocalizacaoUsuario(){

  if (navigator.geolocation){
    navigator.geolocation.getCurrentPosition(this.exibirPosicao);

    }
  else{ console.log("O seu navegador não suporta Geolocalização")}
  }


  constructor(
    public produtos:ProdutosService,
    public responsivo:ResponsivoService
    // public cabecalho:CabecalhoComponent


  ){



  }
}

