import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild} from '@angular/core';
import { range } from 'rxjs';
import { LinhasService } from '../produtos/linhas.service';
import { GlobalService } from '../servicos/global.service';
import { ResponsivoService } from '../servicos/responsivo.service';

@Component({
  selector: 'app-produtos-linha-index',
  templateUrl: './produtos-linha-index.component.html',
  styleUrls: ['./produtos-linha-index.component.css']
})
export class ProdutosLinhaIndexComponent implements OnInit {

  tituloPaginas = 'PRODUTOS POR LINHA';
  corTitulo     = "produto-linha";
  corTituloSvg  = "produto-linha-svg";

  constructor(
    public linhas:LinhasService,
    public responsivo:ResponsivoService,
    public global:GlobalService

  ) { }


  qtdTemp:any = [];


  ngOnInit(): void {
    this.linhas.exibirLinha();

  }

}
