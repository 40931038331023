import { Component, OnInit } from '@angular/core';
import { FornecedoresService } from 'src/app/fornecedores.service';

@Component({
  selector: 'app-produto-fornecedor',
  templateUrl: './produto-fornecedor.component.html',
  styleUrls: ['./produto-fornecedor.component.css']
})
export class ProdutoFornecedorComponent implements OnInit {


  tituloPaginas = 'PRODUTOS POR FORNECEDOR';
  corTitulo = "produto-fornecedor";
  corTituloSvg = "produto-fornecedor-svg";



  

  constructor(
    public fornecedores:FornecedoresService,

  ) {

    fornecedores.exibirFornecedores();
  }

  ngOnInit(): void {
  }

}
