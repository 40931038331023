<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>


<div class="container-fluid">
  <div class="container col-11">
    <div class="row mt-5 mb-4">
      <ul class="text-center row">
        <li class="col-xl-4 col-lg-4 col-md-6 col-12 mb-5" *ngFor="let itens of sobre">
          <img [src]="'assets/imagens/' + itens.linkImg + '.svg'">
          <h4 class="mt-4">{{itens.titulo}}</h4>
          <p class="pl-3 pr-3">{{itens.conteudo}}</p>
        </li>
      </ul>
    </div>
    <div class="row p-0 position-relative">
      <img class="position-absolute linha" src="assets/imagens/linha.svg" alt="">
      <h4 class="col-12 p-0 mb-4">NOSSA HISTÓRIA</h4>
      <ng-container *ngFor="let time of timeLine; index as i">
        <app-time-line class="col-12 mb-5" [ano]="time.ano" [descricao]="time.descricao" [link]="time.link" [posicao]="i">
        </app-time-line>
      </ng-container>
    </div>

  </div>
</div>
