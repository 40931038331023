import { Component, Input, OnInit } from '@angular/core';
import { ResponsivoService } from 'src/app/servicos/responsivo.service';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.css']
})
export class TimeLineComponent implements OnInit {


  @Input()
  ano:number;

  @Input()
  descricao:string;

  @Input()
  link:string;

  @Input()
  posicao:number

  classeEsquerda = 'justify-content-start text-right';
  classeDireita = 'justify-content-end text-left pl-5';




  constructor(
    public responsivo:ResponsivoService

  ) { }

  ngOnInit(): void {
  }

}
