import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produto-pagina',
  templateUrl: './produto-pagina.component.html',
  styleUrls: ['./produto-pagina.component.css']
})
export class ProdutoPaginaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
