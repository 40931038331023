import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {





   public baseUrl = 'https://www.novacasadistribuidora.com/site2021backend/public/API/';






   //public baseUrl = 'http://localhost:8000/API/';

  // public baseUrl = 'http://192.168.0.101:8000/API/';


  voltarTopoPagina() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }



  urlImagem(id){
    return "assets/imagens/produtos/alta-resolucao/" + id + ".jpg";
  }

  urlLogoFornecedor(id){
    return "assets/imagens/fornecedores/logo-pequena/" + id + ".png";
  }


  constructor() { }
}
