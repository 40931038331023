<div class="row mt-5">
    <app-buscar class="col-xl-7 col-lg-7 col-12"></app-buscar>
        <div class="row col mt-xl-0 mt-lg-0 mt-3">
            <a class="col ml-xl-4 ml-lg-4 ml-0 mr-4">
                <button class="col lancamentos" routerLink='/produtos/lancamentos'>
                    <img src="assets/imagens/icon-button-lancamentos.svg">
                    LANÇAMENTOS
                </button>
            </a>

            <a class="col" routerLink='/produtos/fornecedores'>
                <button class="col fornecedores">
                    <img src="assets/imagens/icon-button-fornecedores.svg">
                    FORNECEDORES
                </button>
            </a>
        </div>
</div>