import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-g8',
  templateUrl: './g8.component.html',
  styleUrls: ['./g8.component.css']
})
export class G8Component implements OnInit {

  redirecionamento: boolean;

  constructor() { }

  ngOnInit(): void {
    window.open('https://g8distribuicao.com.br/');
    history.go(-1);
  }

}
