import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { range } from 'rxjs';
import { GlobalService } from './servicos/global.service';

@Injectable({
  providedIn: 'root'
})
export class FornecedoresService {

  fornecedoresUrl = this.global.baseUrl + 'fornecedores/';
  fornecedoresDestaqueUrl = this.global.baseUrl + 'fornecedores-destaque';


  urlLogoFornecedoresMedia: String = 'assets/imagens/fornecedores/logo-media/';

  fornecedoresAPI: any = [];
  fornecedoresDestaqueAPI: any = [];

  qtdColCarroselFornecedores: number = 9;
  qtdCarroselFornecedor: any = [];

  qtdAPIFornecedores: number;




  exibirFornecedores() {
    this.http
      .get(this.fornecedoresUrl)
      .subscribe((data: any) => {
        this.fornecedoresAPI = data;
      });
  }


  exibirFornecedoresDestaque() {
    this.http
      .get(this.fornecedoresDestaqueUrl)
      .subscribe((data: any) => {
        this.fornecedoresDestaqueAPI = data;
        let qtdRetornadaFornecedoresDestaque = this.qtdAPIFornecedores = data.length;

        //Define quantas colunas terá o carroseu
        range(1, (qtdRetornadaFornecedoresDestaque / this.qtdColCarroselFornecedores))
          .subscribe(qtd => this.qtdCarroselFornecedor.push(qtd));
      });

  }

  constructor(
    public http: HttpClient,
    private global: GlobalService

  ) {



  }
}
