import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ObserversModule } from '@angular/cdk/observers';
import { range } from 'rxjs';
import { ResponsivoService } from '../servicos/responsivo.service';
import { GlobalService } from '../servicos/global.service';
import { ProdutosService } from './produtos.service';

@Injectable({
  providedIn: 'root'
})

export class LinhasService {




  // urls locais--------------------------------------------------
  linhasURL = this.global.baseUrl + 'linhas/';
  gruposURL = this.global.baseUrl + 'grupos/';
  subGruposURL = this.global.baseUrl + 'subgruposgrupo/';
  subGruposURLId = this.global.baseUrl + 'subgrupos/';
  qtdLinhasURL = this.global.baseUrl + 'contar-linhas/';
  grupoLinhaURL = this.global.baseUrl + 'gruposlinhas/';
  // ----------------------------------------------------------------


  linhaClicado: number;
  posicaoClicado: number = 0;

  linhaAtiva: number;
  linhaAtivaAnteriormente: number;

  grupoAtivo: number;
  subGrupoAtivo = 0;

  grupoPosicao0: number;
  paginaAtiva: boolean;

  linhasAPI: any = [];
  gruposAPI: any = [];
  grupoLinhaAPI: any = [];
  subGruposAPI: any = [];

  linhasAPIClicada: any = [];
  gruposAPIClicada: any = [];
  subGruposAPIClicada: any = [];

  qtdAPILinhas: number;

  cliques = 0;


  qtdColCarroselLinha: number = 6;
  qtdPagCarroselLinha: any = [];






  mudarEstadoLinha(i) {
    this.cliques += 1; //Conta cliques

    let linhaAnterior = this.linhaClicado; //salva a ultima linha clicada

    this.linhaClicado = this.linhasAPI[i].id; // adiciona a linha clicada atualmente

    //Verifica se foi clicado na mesma linha, caso sim, fecha
    if (linhaAnterior == this.linhasAPI[i].id && this.cliques == 2) {
      this.cliques = 0;
      this.linhaClicado = 0;
    }

    if (linhaAnterior != this.linhasAPI[i].id) {
      this.cliques = 1;
    }

    // Abre todos os menus caso tenha alguma linha ativa.
    if (this.linhaAtiva != 0 && this.cliques == 1) {
      // console.log("Linha clicada" + this.cliques);
      this.linhaAtiva = 0;
      console.log("Vamos fazer o teste");
      // this.clicarFora();
    }

    //Salva temporáriamente a posição clicada
    this.posicaoClicado = i;

    //Volta o estado do subgrupo para o valor padrão
    this.subGrupoAtivo = 0;
  }

  numeroCliquesForaMenu: number;

  contarCliquesForaMenu() {
    this.numeroCliquesForaMenu += 1;
    console.log(this.numeroCliquesForaMenu);
  }

  clicarFora() {
    this.cliques += 1;
    if (this.cliques == 1) {
      this.linhaAtiva = this.linhaAtivaAnteriormente;
    }
    this.cliques = 0;
  }

  exibirLinha() {
    this.http
      .get(this.linhasURL)
      .subscribe((data: any) => {
        this.linhasAPI = data;
        let qtdRetornadaLinhas = this.qtdAPILinhas = data.length;

        //Define quantas colunas terá o carroseu
        this.qtdPagCarroselLinha = [];

        range(1,(qtdRetornadaLinhas / this.qtdColCarroselLinha))
        .subscribe(qtd => {
          this.qtdPagCarroselLinha.push(qtd)
        });
      });
  }

  exibirGrupo() {
    this.http
      .get(this.gruposURL)
      .subscribe(data =>this.gruposAPI = data);
  }

  exibirGrupoLinha(linha){
      this.http
      .get(this.grupoLinhaURL + linha)
      .subscribe(data =>
        {
          this.grupoLinhaAPI = data;
          let primeiroGrupo = data[0].cod_grupo;
          this.inserirProdutoNomeLinha(primeiroGrupo);
        }
        );
  }

  inserirProdutoNomeLinha(id){
    if (this.grupoAtivo == undefined) {
      this.ativarGrupo(id);
      this.produtos.exibirProdutosNome(id);
    }
  }




  exibirSubGrupo(grupoAtivo = this.grupoAtivo) {
    this.http
      .get(this.subGruposURL + grupoAtivo)
      .subscribe(data => this.subGruposAPI = data)
  }

  exibirSubGrupoClicado(id) {
    this.http
      .get(this.subGruposURLId + id)
      .subscribe(data => this.subGruposAPIClicada = data)
  }

  exibirGrupoAtivo(grupoAtivo = this.grupoAtivo) {
    this.http
      .get(this.gruposURL + grupoAtivo)
      .subscribe(data => this.gruposAPIClicada = data)
  }

  exibirLinhaAtiva(linhaAtiva = this.linhaAtiva) {
    this.http
      .get(this.linhasURL + linhaAtiva)
      .subscribe(data => this.linhasAPIClicada = data)
  }

  ativarLinha(id){
    this.linhaAtiva = id;
    console.log("A linha é " + id);
  }

  ativarSubgrupo(id) {
    this.subGrupoAtivo = id;
  }

  ativarGrupo(id) {
    this.grupoAtivo = id;
  }

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private produtos:ProdutosService
     ) {
    this.linhaClicado = 0;
  }
}
