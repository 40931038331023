import { Component, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/servicos/global.service';
import { ProdutosService } from '../produtos.service';
import { LinhasService } from '../linhas.service';
import { FornecedoresService } from 'src/app/fornecedores.service';
import { HttpClient } from '@angular/common/http';


import { FormControl, FormGroup, FormBuilder, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-produto-modal-sistema',
  templateUrl: './produto-modal-sistema.component.html',
  styleUrls: ['./produto-modal-sistema.component.css']
})
export class ProdutoModalSistemaComponent implements OnInit {
  imagemProduto: String;

  selected = 'option2';

  linhaSelecionada: number = this.linhas.linhaAtiva;

  nomeProduto: FormGroup;





  constructor(
    public produtos: ProdutosService,
    public modal: NgbActiveModal,
    public global: GlobalService,
    public linhas: LinhasService,
    public fornecedores: FornecedoresService,
    public http: HttpClient,
    private fb: FormBuilder
  ) {
    
  }

  ngOnInit(): void {
    this.produtos.exibirProdutoNomeAtivo();
    this.produtos.exibirProdutoDescricao();
    this.produtos.exibirProdutoIndex();
    this.produtos.exibirProdutoDescricaoIndex();
    this.produtos.exibirProdutoNomeIndex();
    this.produtos.exibirIndex();
    this.linhas.exibirGrupoLinha(this.linhaSelecionada);
    this.fornecedores.exibirFornecedores();
    this.linhas.exibirSubGrupo(this.linhas.grupoAtivo);

    // this.insertOrUpdateProdutos();

    // this.nomeProduto = new FormGroup({
    //   id: new FormControl(),
    //   nome: new FormControl(),
    //   cod_grupo: new FormControl(),
    //   cod_subgrupo: new FormControl(),
    //   cod_index: new FormControl(),
    //   cod_fornecedor: new FormControl(),
    //   cod_linha: new FormControl(),
    //   cod_img_alt: new FormControl()
      
    // });

    this.nomeProduto = this.fb.group({
      id: this.produtos.id,
      nome: this.produtos.nome,
      cod_grupo: '',
      cod_subgrupo: '',
      cod_index: '',
      cod_fornecedor: '',
      cod_linha: '',
      cod_img_alt: ''
    })

    // this.nomeProduto.valueChanges.subscribe(console.log);

  }


  

  insertOrUpdateProdutos(data) {
    this.http
      .post('http://localhost:3000/tb_nome/', data)
      .subscribe(
        resultado => {
          console.log(resultado)
        },
        erro => {
          if (erro.status == 400) {
            console.log(erro);
          }
        }
      )
  }
}
