import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filiais-index',
  templateUrl: './filiais-index.component.html',
  styleUrls: ['./filiais-index.component.css']
})
export class FiliaisIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  filiais: any[] = [


    {
      "nome": "MATRIZ DISTRITO FEDERAL",
      "img": "assets/imagens/filial-df.jpg",
      "link": "*",
      /*
      "atributos": [
        "11.000 itens",
        "14.900 m²",
        "Brasília - DF",
      ],
      */
    },

    {
      "nome": "FILIAL GOIÁS",
      "img": "assets/imagens/filial-go.jpg",
      "link": "*",
    },

    {
      "nome": "FILIAL MINAS",
      "img": "assets/imagens/filial-mg.jpg",
      "link": "*",
    },

    {
      "nome": "FILIAL PARÁ",
      "img": "https://www.novacasadistribuidora.com/assets/imagens/filial-pa.jpg",
      "link": "*",
    },

    {
      "nome": "FILIAL BAHIA",
      "img": "https://www.novacasadistribuidora.com/assets/imagens/filial-ba.jpg",
      "link": "*",
    }



  ]


}
