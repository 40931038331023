<button type="button" class="close p-2" aria-label="Close" (click)="modal.close()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="corpo-modal">
  <div class="">
    <div class="row justify-content-between corpo-produto">
      <div class="imagem-produto col-xl-5 col-12 pr-xl-4 pr-0 mb-4">
        <img *ngFor="let produto of produtos.produtosNomeApiAtivo" class="logo col-3"
          [src]="global.urlLogoFornecedor(produto.cod_fornecedor)">


        <ng-container *ngIf="produtos.produtosDescricaoApiIndex.length > 1  then carrousel else imagemUnica">
        </ng-container>

        <!-- template para escolher quantidade de imagens, caso seja mais que uma abrirá um carroseu -->
        <ng-template #carrousel>
          <ngb-carousel class="col">
            <ng-container *ngFor="let imagemProdutos of produtos.produtosDescricaoApiIndex; index as i">
              <ng-template ngbSlide>
                <ng-container>
                  <img class="img-fluid" [src]="global.urlImagem(imagemProdutos.cod_nc_img)">
                </ng-container>
              </ng-template>
            </ng-container>
          </ngb-carousel>
        </ng-template>

        <ng-template #imagemUnica>
          <div class="col">
            <img class="img-fluid" [src]="global.urlImagem(produtos.produtoAtivo)">
          </div>
        </ng-template>
        <!-- --------------------------------------------------------------------------------------- -->

      </div>
      <div class="col">
        <div class="table-responsive-lg">
          <h1 *ngFor="let produto of produtos.produtosNomeApiAtivo">{{produto.nome}}</h1>
          <table class="table table-sm table-hover col table-striped">
            <thead class="thead-dark">
              <tr>
                <th>CÓD</th>
                <th>DESCRIÇÃO</th>
                <th>REF. FÁBRICA</th>
                <th>EMBALAGEM</th>
                <th>EAN</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let produto of produtos.produtosDescricaoApi">
                <tr>
                  <td scope="row">{{produto.id}}</td>
                  <td>{{produto.descricao}}</td>
                  <td>{{produto.codfab}}</td>
                  <td>{{produto.multiplo}} {{produto.unidade}}</td>
                  <td>{{produto.ean}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>


    </div>
    <app-produto-semelhante></app-produto-semelhante>
  </div>

</div>
