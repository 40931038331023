import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../servicos/global.service';

@Component({
  selector: 'app-a-nova-casa',
  templateUrl: './a-nova-casa.component.html',
  styleUrls: ['./a-nova-casa.component.css']
})
export class ANovaCasaComponent implements OnInit {

  tituloPaginas = 'A NOVA CASA';
  corTitulo     = "produto-linha";
  corTituloSvg  = "produto-linha-svg";






  sobre:any = [
    {
      titulo: "MISSÃO",
      conteudo: "Distribuir soluções em produtos e serviços para construção afins, com confiança, ética, transparência e agilidade, agregando valor a toda a cadeia.",
      linkImg: "icon-missao"
    },

    {
      titulo: "VISÃO",
      conteudo: "Ser reconhecida como uma empresa que entrega soluções em nosso segmento, gerando negócios, oportunidades e crescimento sustentável com o público.",
      linkImg: "icon-visao"
    },

    {
      titulo: "VALORES",
      conteudo: "Honestidade, Dinamismo, Visão ampla, Ousadia, Pessoas",
      linkImg: "icon-valores"
    },

  ]

  timeLine: any = [

    {
      ano: '2021',
      descricao: 'Reinauguração da Filial Minas Gerais com 13.000m² de área construída',
      link: '*'
    },

    {
      ano: '2020',
      descricao: 'Mesmo com a pandemia, a Nova Casa bate 5 meses de recorde de vendas.',
      link: '*'
    },
    {
      ano: '2018',
      descricao: 'Ampliação do Centro de Distribuição de Minas Gerais e Prêmio de 3° Lugar Nacional pela Revista Revenda',
      link: '*'
    },

    {
      ano: '2016',
      descricao: 'Abertura da filial em Contagem Minas Gerais',
      link: '*'
    },

    {
      ano: '2015',
      descricao: 'Decisão de abrir uma nova Filial em Minas Gerais',
      link: '*'
    },

    {
      ano: '2014',
      descricao: 'Reconhecimento nacional - 4º Lugar Nacional pela Revista Anamaco',
      link: '*'
    },

    {
      ano: '2012',
      descricao: 'Abertura do novo Centro de Distribuição em Brasília - DF',
      link: '*'
    },

    {
      ano: '2006',
      descricao: 'Queda do galpão de Goiânia e vinda definitiva para Brasília',
      link: '*'
    },

    {
      ano: '2003',
      descricao: 'Mudança da matriz de Anápolis para Goiânia',
      link: '*'
    },

    {
      ano: '2002',
      descricao: 'Abertura filial Brasília, em Taguatinga',
      link: '*'
    },

    {
      ano: '1999',
      descricao: 'Início do atendimento voltado apenas para o atacado',
      link: '*'
    },
    {
      ano: '1994',
      descricao: 'Fechamento da Costa Materiais para construção e abertura da razão social Nova Casa',
      link: '*'
    },

    {
      ano: '1991',
      descricao: 'Abertura da razão social Costa Materiais para construção em Anápolis - GO',
      link: '*'
    },

    {
      ano: '1987',
      descricao: 'Mudança para Anápolis e aquisição de um  depósito de material de construção em sociedade com o pai',
      link: '*'
    },


  ]


  constructor(
    public global:GlobalService

  ) { }

  ngOnInit(): void {

    this.global.voltarTopoPagina();
  }

}
