import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { BannerComponent } from './banner/banner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FiliaisIndexComponent } from './filiais-index/filiais-index.component';
import { TituloPaginasComponent } from './titulo-paginas/titulo-paginas.component';
import { ProdutoDestaqueIndexComponent } from './produto-destaque-index/produto-destaque-index.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { ProdutosLinhaIndexComponent } from './produtos-linha-index/produtos-linha-index.component';
import { ProdutoPaginaComponent } from './produtos/produto-pagina/produto-pagina.component';
import { IndexPaginaComponent } from './index-pagina/index-pagina.component';
import { BuscarComponent } from './produtos/buscar/buscar.component';
import { AppRoutingModule } from './app.routing';
import { ProdutoListaComponent } from './produtos/produto-lista/produto-lista.component';
import { ProdutoComponent } from './produtos/produto/produto.component';
import { ProdutoMenuComponent } from './produtos/produto-menu/produto-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { ProdutoUnicoComponent } from './produtos/produto-unico/produto-unico.component';
import { ProdutoModalComponent } from './produtos/produto-modal/produto-modal.component';
import { ValoresUnicosPipe } from './valores-unicos.pipe';
import { ContatoComponent } from './contato/contato.component';
import { ParceirosComponent } from './parceiros/parceiros.component';
import { BuscarCabecalhoComponent } from './produtos/buscar/buscar-cabecalho/buscar-cabecalho.component';
import { BuscarProdutoComponent } from './produtos/buscar/buscar-produto/buscar-produto.component';
import { InfoWinthorDirective } from './diretivas/info-winthor.directive';
import { LancamentosComponent } from './sistemas/lancamentos/lancamentos.component';
import { ProdutoFornecedorComponent } from './produtos/produto-fornecedor/produto-fornecedor.component';
import { ProdutoListaFornecedorComponent } from './produtos/produto-lista-fornecedor/produto-lista-fornecedor.component';
import { ProdutoListaLancamentosComponent } from './produtos/produto-lista-lancamentos/produto-lista-lancamentos.component';
import { FornecedoresIndexComponent } from './fornecedores-index/fornecedores-index.component';
import { ProdutoListaLinhaComponent } from './produtos/produto-lista-linha/produto-lista-linha.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { MenuResponsivoComponent } from './cabecalho/menu-responsivo/menu-responsivo.component';
import { ProdComponent } from './sistemas/lancamentos/prod/prod.component';
import { SistemaModule } from './sistema/sistema.module';
import { ResponsivoService } from './servicos/responsivo.service';
import { ANovaCasaComponent } from './a-nova-casa/a-nova-casa.component';
import { TimeLineComponent } from './a-nova-casa/time-line/time-line.component';
import { BuscarCabecalhoListaComponent } from './produtos/produto-lista/buscar-cabecalho-lista/buscar-cabecalho-lista.component';
import { G8Component } from './g8/g8.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProdutoModalSistemaComponent } from './produtos/produto-modal-sistema/produto-modal-sistema.component';
import { ProdutoSemelhanteComponent } from './produtos/produto-semelhante/produto-semelhante.component';

import {MatInput, MatInputModule} from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatOption, MatOptionModule } from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { ColaboradorComponent } from './portais/colaborador/colaborador.component';
import { GestorComponent } from './portais/gestor/gestor.component';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';



@NgModule({
  declarations: [
    AppComponent,
    CabecalhoComponent,
    BannerComponent,
    FiliaisIndexComponent,
    TituloPaginasComponent,
    ProdutoDestaqueIndexComponent,
    FooterComponent,
    ProdutosLinhaIndexComponent,
    ProdutoPaginaComponent,
    IndexPaginaComponent,
    ProdutoMenuComponent,
    BuscarComponent,
    ProdutoListaComponent,
    ProdutoComponent,
    ProdutoUnicoComponent,
    ProdutoModalComponent,
    ValoresUnicosPipe,
    ContatoComponent,
    ParceirosComponent,
    BuscarCabecalhoComponent,
    BuscarProdutoComponent,
    InfoWinthorDirective,
    LancamentosComponent,
    ProdutoFornecedorComponent,
    ProdutoListaFornecedorComponent,
    ProdutoListaLancamentosComponent,
    FornecedoresIndexComponent,
    ProdutoListaLinhaComponent,
    MenuResponsivoComponent,
    ProdComponent,
    ANovaCasaComponent,
    TimeLineComponent,
    BuscarCabecalhoListaComponent,
    G8Component,
    ProdutoModalSistemaComponent,
    ProdutoSemelhanteComponent,
    ColaboradorComponent,
    GestorComponent
  ],


  imports: [
    BrowserAnimationsModule ,
    BrowserModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollingModule,
    BrowserModule,
    InfiniteScrollModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgxGoogleAnalyticsModule.forRoot('UA-55696802-1'),
    NgxGoogleAnalyticsRouterModule.forRoot({ include: [ '*', ], }) ],

  exports: [
  ],

  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

