<div class="container-fluid">
    <div class="filiais">
        <div class="filial container col-12 mb-5" *ngFor="let filial of filiais">
            <div class="div-cinza"></div>
            <div class="col-11 conteudo-filial justify-content-md-center">
                <div class="col-12 mb-2">
                    <h2>{{filial.filial}}</h2>
                </div>

                <div class="row col conteudo-filial ml-a mr-a">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-12 mr-4 mb-3">
                        <img class="col" [src]="filial.imagemFilial">
                        <div class="endereco mt-3 mb-3">
                            <h4>Endereço:</h4>
                            <p>{{filial.endereco}}</p>
                        </div>
                        <div class="telefone">
                            <h4>Telefone:</h4>
                            <p>{{filial.telefone}}</p>
                        </div>
                    </div>

                    <div class="col mapa">
                        <ng-template *ngIf="filial.id==4 then mapaFilial4"></ng-template>
                        <ng-template *ngIf="filial.id==12 then mapaFilial12"></ng-template>
                        <ng-template *ngIf="filial.id==2 then mapaFilial2"></ng-template>
                        <ng-template *ngIf="filial.id==5 then mapaFilial5"></ng-template>
                        <ng-template *ngIf="filial.id==6 then mapaFilial6"></ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Mapa que será inserido conforme condição -->

<ng-template #mapaFilial2>
    <iframe class="col"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.433957691996!2d-47.96768498514186!3d-16.042955288897197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935986f4b14a254d%3A0x9a78c4f4601bb60e!2sNova%20Casa%20Distribuidora%20DF%20(Matriz)!5e0!3m2!1spt-BR!2sbr!4v1590613590342!5m2!1spt-BR!2sbr">
    </iframe>
</ng-template>

<ng-template #mapaFilial4>
    <iframe class="col"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1911.438829044456!2d-49.2024426917852!3d-16.632908127151563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935eed8cd3aca84f%3A0xa68f77707706a995!2sNova%20Casa%20Distribuidora%20Goi%C3%A2nia!5e0!3m2!1spt-BR!2sbr!4v1590613651894!5m2!1spt-BR!2sbr">
    </iframe>
</ng-template>

<ng-template #mapaFilial12>
    <iframe class="col"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.2549816987234!2d-44.032904585086314!3d-19.871452286636448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa693b91e0e1dc9%3A0xb037a78f4a9a825d!2sNova%20Casa%20Distribuidora%20Minas%20Gerais!5e0!3m2!1spt-BR!2sbr!4v1666291724648!5m2!1spt-BR!2sbr">
    </iframe>
</ng-template>

<ng-template #mapaFilial5>
  <iframe class="col"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6778512254878!2d-48.27613018524602!3d-1.3699766990008269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92a4f78606590c11%3A0xba67eba430e0df5e!2sNova%20casa%20Distribuidora%20Para!5e0!3m2!1spt-BR!2sbr!4v1666291943711!5m2!1spt-BR!2sbr">

  </iframe>
</ng-template>

<ng-template #mapaFilial6>
  <iframe class="col"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7780.312682802238!2d-38.431606!3d-12.833172!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x716137105f6bd39%3A0xedff5db22fe11228!2sNova%20Casa%20Distribuidora%20Bahia!5e0!3m2!1spt-BR!2sbr!4v1681748636891!5m2!1spt-BR!2sbr">

  </iframe>
</ng-template>
