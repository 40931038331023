<div class="row justify-content-md-center">
    <div class="container">
        <form class="pesquisa col-8 mb-3 mt-3" type="post" >
            <div class="form-group">
                <label>Buscar Produtos</label>
                <div class="row">
                    <input name="pesquisa" class="form-control col mr-2" aria-describedby="emailHelp" [(ngModel)]="catalogo.produtosFiltro">
                    <button (click)="catalogo.ativarProdutos()" type="submit" class="btn btn-primary col-1">Buscar</button>
                </div>
                <small id="emailHelp" class="form-text text-muted">digite o código separado por vírgula ou espaço. Ex: 123,456 ou 123 456</small>
            </div>
        </form>

        <ng-container *ngFor="let item of catalogo.array; index as i; count as c" >
            <div class="fundo pt-5 pl-5 pr-5 pb-3 col-8 mb-4" #ProdutoNome>
                <img class="img-lancamento mb-2" src="assets/imagens/nome-lancamento.png">
                <div class="container-fluid fundo-produtos">
                    <div class="row">
                        <ng-container *ngFor="let produtos of catalogo.produtosLancamentosNome; index as i; count as c" >
                            <app-prod [id]="i" #produto [ngClass]="i%2==0 && (c%2==1 && i==c-1) ?'col-12': 'col-6'"
                                [codFornecedor]="produtos.cod_fornecedor"
                                [nome]="produtos.nome"
                                [codNome]="produtos.cod_nome"
                                [produtosDescricao]="catalogo.produtosLancamentos"
                                >
                            </app-prod>
                        </ng-container>
                    </div>
                </div>
                <img class="mt-3" src="assets/imagens/logo-varicacao-branca.png">
            </div>
        </ng-container>
    </div>
</div>