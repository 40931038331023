<div class="row produtos-semelhantes m-0">
    <h3 class="">PRODUTOS SEMELHANTES</h3>

    <div class="row m-0">
      <ng-container *ngFor="let produtosIndex of produtos.produtosNomeIndex">
        <a class="col-3 p-2" (click)="
            produtos.exibirModalSemRota(produtosIndex.id, produtosIndex.cod_fornecedor);
            " [ngClass]="produtos.classeProdutoUnico">
          <app-produto-unico class="col-11" [nome]="produtosIndex.nome" [codFornecedor]="produtosIndex.cod_fornecedor"
            [id]="produtosIndex.id">
          </app-produto-unico>
        </a>
      </ng-container>
    </div>

  </div>