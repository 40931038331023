<footer class="container-fluid mt-5">
    <div class="col-11 container footer2 pt-3">
        <div class="row justify-content-center">
            <div class="logo col-xl-4 col-lg-4 col-md col text-center">
                <img class="img-fluid mt-3 mb-3" src="assets/imagens/logo_rodape.svg">
                <!-- <h6 class="mb-4 col">(61) 3355-5100</h6> -->
            </div>
            <div class="col">
                <h5 class="p-0 mb-2">INSTITUCIONAL</h5>
                <ul class="col p-0">
                    <li class="pb-1" *ngFor='let pagina of paginas'>
                        <a [routerLink]="pagina.link">
                            <img src="assets/imagens/marcador_rodape.svg">
                            {{pagina.nome}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-xl-5 col-lg-4 col-5 d-xl-inline d-lg-inline d-md-inline d-none">
                <h5 class="mb-2">PRODUTOS</h5>
                <ul class="col p-0 produtos">
                    <li class="pb-1" *ngFor='let linha of linhas.linhasAPI'>
                        <a [routerLink]="'/produtos/linha/'+ linha.id" (click)="global.voltarTopoPagina(); linhas.ativarGrupo(undefined)">
                            <img src="assets/imagens/marcador_rodape.svg">
                            {{linha.nome}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row footer-linha-final justify-content-center pt-3 pt-1">
        <p>{{anoAtual}} - Nova Casa Distribuidora de Materiais para Construção S/A - Todos os direitos reservados</p>
    </div>
</footer>
