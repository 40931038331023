import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexPaginaComponent } from './index-pagina/index-pagina.component';
import { ProdutoComponent } from './produtos/produto/produto.component';
import { ProdutoListaComponent } from './produtos/produto-lista/produto-lista.component';
import { ContatoComponent } from './contato/contato.component';
import { ParceirosComponent } from './parceiros/parceiros.component';
import { BuscarProdutoComponent } from './produtos/buscar/buscar-produto/buscar-produto.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { LancamentosComponent } from './sistemas/lancamentos/lancamentos.component';
import { ProdutoFornecedorComponent } from './produtos/produto-fornecedor/produto-fornecedor.component';
import { ProdutoListaFornecedorComponent } from './produtos/produto-lista-fornecedor/produto-lista-fornecedor.component';
import { ProdutoListaLancamentosComponent } from './produtos/produto-lista-lancamentos/produto-lista-lancamentos.component';
import { ProdutoListaLinhaComponent } from './produtos/produto-lista-linha/produto-lista-linha.component';
import { RcasComponent } from './sistema/rcas/rcas.component';
import { ANovaCasaComponent } from './a-nova-casa/a-nova-casa.component';
import { G8Component } from './g8/g8.component';
import { ColaboradorComponent } from './portais/colaborador/colaborador.component';
import { GestorComponent } from './portais/gestor/gestor.component';

const routes: Routes = [
    { path: '', component: IndexPaginaComponent },
    { path: 'produtos', component: ProdutoComponent },
    { path: 'produtos/linha/:idlinha', component: ProdutoListaLinhaComponent },
    { path: 'produtos/linha/:idlinha/:id', component: ProdutoListaLinhaComponent },
    { path: 'produtos/lancamentos', component: ProdutoListaLancamentosComponent },
    { path: 'produtos/lancamentos/:id', component: ProdutoListaLancamentosComponent },
    { path: 'produtos/fornecedores', component: ProdutoFornecedorComponent },
    { path: 'produtos/fornecedores/:idFornecedor', component: ProdutoListaFornecedorComponent },
    { path: 'produtos/fornecedores/:idFornecedor/:idProduto', component: ProdutoListaFornecedorComponent },
    { path: 'produtos/:idlinha/:idGrupo', component: ProdutoListaComponent },
    { path: 'produtos/:idlinha/:idGrupo/:id', component: ProdutoListaComponent },
    { path: 'sobre', component:ANovaCasaComponent},
    { path: 'colaboradores', component:ColaboradorComponent},
    { path: 'gestores', component:GestorComponent},

    { path: 'g8', component: G8Component},


    { path: 'contato', component: ContatoComponent },
    { path: 'parceiros', component: ParceirosComponent },
    { path: 'buscar', component: BuscarProdutoComponent },
    { path: 'buscar/:id', component: BuscarProdutoComponent },
    { path: 'cabecalho', component: CabecalhoComponent },
    { path: ':id', component: IndexPaginaComponent },

    { path: 'sistema/lancamentos', component: LancamentosComponent },
    { path: 'sistema/rcas', component: RcasComponent },


    { path: '**', component: IndexPaginaComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]


})

export class AppRoutingModule { }
