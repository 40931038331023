<div class="container logo-fornecedores col-11 pt-3">
  <ngb-carousel [showNavigationArrows]='false' [showNavigationIndicators]='false' [interval]='4000'>
    <ng-template ngbSlide *ngFor="let slide of fornecedores.qtdCarroselFornecedor; index as i1; count as c">
      <div class="row">
        <ng-container *ngFor="let fornecedor of fornecedores.fornecedoresDestaqueAPI; index as i2">
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6" style="flex: 0 0 10%;" *ngIf="i2 < (i1+1)*fornecedores.qtdColCarroselFornecedores && (i1)*fornecedores.qtdColCarroselFornecedores <= i2">
            <a class="" [routerLink]="'/produtos/fornecedores/'+fornecedor.cod_fornecedor">
              <img class="img-fluid col-xl-11 col-lg-12 col-sm-10" [src]="'assets/imagens/fornecedores/logo-media/' + fornecedor.cod_fornecedor + '.png'">
            </a>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
