<div class="col p-0">
  <div class="row p-0 mb-4 time-line-esquerda position-relative"
    [ngClass]="posicao%2 == 0? classeEsquerda : classeDireita">
    <img class="elipse position-absolute" src="assets/imagens/elipse-timeline.svg">
    <div class="col-xl-6 col-lg-6 col-md-l col-12 row">
      <img class="" *ngIf="posicao%2 == 1 && responsivo.breakpointAtivo != 'SM'" src="assets/imagens/seta_time-line-direita.svg">

      <div class="col-11 time-line p-4">
        <h6>{{ano}}</h6>
        <p>{{descricao}}</p>
        <!-- <button>Veja mais</button> -->
      </div>
      <img *ngIf="posicao%2 == 0 && responsivo.breakpointAtivo != 'SM'" src="assets/imagens/seta_time-line-esquerda.svg">
    </div>
  </div>
</div>
