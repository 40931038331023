<app-titulo-paginas [tamanhoColuna]="'col-10'" [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo"
  [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container-fluid produtos-destaque-background mt-5 pb-5">
  <div id="carouselLancamentosIndex" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <ng-container *ngFor="let slide of responsivo.qtdPagCarrosel; index as i">
        <li data-target="#carouselLancamentosIndex" data-slide-to="i" [ngClass]="i == 0? 'active':false"></li>
      </ng-container>
    </ol>

    <div class="col-10 container carousel-inner">
      <ng-container *ngFor="let slide of responsivo.qtdPagCarrosel; index as i0; count as c">
        <div class="col carousel-item" [ngClass]="i0 == 0? 'active':false">
          <div class="row">
            <ng-container *ngFor="let produto of produtos.produtosLancamentosIndexApi; index as i; count as qtd">
              <a class="col p-2" [routerLink]="produto.id"
                *ngIf="i < (i0+1)*responsivo.qtdColCarrosel && (i0)*responsivo.qtdColCarrosel <= i" (click)='
                produtos.ativarNomeProdutoAtivo(produto.id);
                produtos.exibirModal();
                produtos.indexClicado(produto.cod_index);'>
                <app-produto-unico class="col"
                  [nome]="produto.nome" 
                  [fornecedor]="produto.fornecedor"
                  [codFornecedor]="produto.cod_fornecedor"
                  [id]="produto.id">
                </app-produto-unico>
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>


    </div>
    <a class="carousel-control-prev" href="#carouselLancamentosIndex" role="button" data-slide="prev">
      <span class="" aria-hidden="true"><img src="assets/imagens/seta_esquerda_destaque.svg"></span>
    </a>
    <a class="carousel-control-next" href="#carouselLancamentosIndex" role="button" data-slide="next">
      <span class="" aria-hidden="true"><img src="assets/imagens/seta_direita_destaque.svg"></span>
    </a>
  </div>
</div>