<app-titulo-paginas [tituloPaginas]="tituloPaginas" [corTitulo]="corTitulo" [corTituloSvg]="corTituloSvg">
</app-titulo-paginas>

<div class="container col-xl-11 col-12 mt-5">
    <div class="col">
        <app-buscar-cabecalho></app-buscar-cabecalho>
    </div>
    <div class="pl-5 col mt-5">
        <ul class="row col">
            <ng-container *ngFor="let fornecedor of fornecedores.fornecedoresAPI">
                <li class="fornecedores col-xl-2 col-lg-3 col-md-4 col-6 pl-2 pr-2 pb-1 text-center" *ngIf="fornecedor.status != 'I'">
                    <div class="col">
                        <a [routerLink]="fornecedor.cod_fornecedor">
                            <img class="mt-2 col-xl-11 col-lg-10 pb-3 pt-2"
                                [src]="fornecedores.urlLogoFornecedoresMedia+fornecedor.cod_fornecedor+'.png'">
                            <h5 class="mt-2 pt-1 pb-2 ">{{fornecedor.fornecedor}}</h5>
                        </a>
                    </div>
                </li>
            </ng-container>

        </ul>
    </div>
</div>