import { Component, OnInit } from '@angular/core';
import { ProdutosService } from '../produtos.service';

@Component({
  selector: 'app-produto-lista-lancamentos',
  templateUrl: './produto-lista-lancamentos.component.html',
  styleUrls: ['./produto-lista-lancamentos.component.css']
})
export class ProdutoListaLancamentosComponent implements OnInit {


  tituloPaginas = 'LANÇAMENTOS';
  corTitulo = "produto-lancamento";
  corTituloSvg = "produto-lancamento-svg";


  constructor(
    public produtos:ProdutosService
  ) {



  }

  ngOnInit(): void {
    this.produtos.exibirProdutoLancamento();

    console.log('é isso: '+  this.produtos.produtosLancamentoApi)
  }

}
