import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/servicos/global.service';
import { LinhasService } from '../linhas.service';
import { ProdutosService } from '../produtos.service';

@Component({
  selector: 'app-produto-lista-linha',
  templateUrl: './produto-lista-linha.component.html',
  styleUrls: ['./produto-lista-linha.component.css'],
})
export class ProdutoListaLinhaComponent implements OnInit {

  tituloPaginas = 'PRODUTOS POR LINHA';
  corTitulo = "produto-linha";
  corTituloSvg = "produto-linha-svg";

  constructor(
    public produtos: ProdutosService,
    public linhas: LinhasService,
    private rotaAtiva: ActivatedRoute,
    private global:GlobalService

  ) {


  }







  acionarLinhaURL() {
    this.rotaAtiva.paramMap.subscribe(params => {
      let linhaAtiva = parseInt(params.get("idlinha"));
      this.produtos.linhaAtiva = linhaAtiva;
      this.linhas.linhaAtivaAnteriormente = linhaAtiva;
      this.linhas.paginaAtiva = true;

      this.linhas.linhaAtiva = linhaAtiva;

      this.linhas.exibirGrupoLinha(linhaAtiva);


    })
  }

  ngOnInit(): void {
    this.acionarLinhaURL();





  }
}
